import React, {useState, useEffect} from 'react'
import Template from '../components/template';
import {Alerts, LabelWarning} from '../components/notify'
import {ServerUrl,  config,  PictureUrl} from '../components/include'
import axios from 'axios'
import { Loader } from '../components/loader';
const CreateAccount =()=> {

	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});

   
	const [isSubmitting, setIsSubmitting] = useState(false);

    var Initials = {
        code:Math.random().toString(36).substr(2,9),
		email:'',
		gender:'Male',
        fullname:'',
        password:'',
        birthDate:'0000-00-00',
        telephone:'',
        address:'',
        nokin_name:'',
        nokin_address:'',
        nokin_relationship:'',
        nokin_phone:'',
        accountNumber:'',
        accountBalance:0,
        passport:'',
        dateCreate:'',
         accountStatus:'Active',
         accountType:'Single Savings Account',
         onlineID:'',
        branchCode:'',
        maritalStatus:'Single'	
    }
	const [customer, setCustomer] = useState(Initials)
    const [image, setImage] = useState({
        passport:'',
        passportFile:''
    })
    

    const [errors, setErrors] = useState({});




    


    const generateID =()=>{
        var num = (Math.random().toString(36).substr(2,9)).toUpperCase() 
		setCustomer({...customer, onlineID : num });
        setErrors({...errors, onlineID:''});
	}

    const generateCode =()=>{
        var num = Math.random().toString().slice(2,12) 
		setCustomer({...customer, accountNumber : num });
        setErrors({...errors, accountNumber:''});
	}

    const removeImageUrl =()=>{
       // document.getElementById('passport').value = '';
        setImage({...image, passport:'', passportFile:''})
    }
    const  handleFile = function(fieldName){
        return function(newValue){                 
            const value = newValue.target.files[0]
          // setImage(value)
       readURL(newValue);
       }
       
      } 
    
      
      const readURL = (input)=>{
           
        let displayMessage = '';
        const PreviewImage = input.target.name;
            var fileName = document.getElementById(PreviewImage).value;
        var idxDot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    
        if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || extFile==="gif" || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" || extFile==="GIF"){
            //TO DO    
            
             var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
        if (FileSize > 2) {
            displayMessage = PreviewImage +': File uploaded is more than maximum size Allow. it exceeds 2 Mb';
            document.getElementById(PreviewImage).value = '';
           //setImage({...image, passport:'', passportFile:''})
        } else {
            if (input.target.files && input.target.files[0]) { //Check if input has files.
    
            
                var reader = new FileReader();//Initialize FileReader.
                reader.onload = function (e) {
                    setImage({...image, passport:e.target.result, passportFile:input.target.files[0]})
                    setErrors({...errors, passport:''});
                };
                reader.readAsDataURL(input.target.files[0]);
    
            }
        }
        }else{
    
            displayMessage = 'Only gif|jpg|png|jpeg|jpe|JPEG|PNG|JPG  files are allowed with maximum of 1 Mb'
            document.getElementById(input.target.name).value = '';
            setImage({...image, passport:''})
        }  
         if(displayMessage.length > 0){
            setErrors({...errors, errorMessage:displayMessage})
            Alerts('Error!', 'danger', displayMessage)
          }
        
    } 


    const handleChange = event =>{		
        let {name, value} = event.target;	
       setCustomer({...customer, [name] : value });
       setErrors({...errors, [name]:''});
    }
    const handleSubmit = event =>{
        event.preventDefault();
         
    setErrors(ValidateInput(customer));
        setIsSubmitting(true);

        window.scrollTo({top:0, left:0, behavior:'smooth'})
    }
    const ValidateInput =(values)=>{
        let errors = {};   
        let email = values.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        
        if(!values.fullname){
            errors.fullname ='Please enter fullname';
        }
        if(!email){
            errors.email ='Please enter valid email address';
        }

 
        if(values.password.length <6){
              errors.password ='Please enter minimum of 6 characters';
          } 

       
        if(!values.accountNumber){
            errors.accountNumber ='This field is required';
        }
        if(!values.onlineID){
            errors.onlineID ='This field is required';
        }


        
        return errors;
        }

        useEffect(()=>{
            if(Object.keys(errors).length === 0 && isSubmitting){
            submit()
            }
            },[errors])
	
	

 function submit(){  
                setNotice({...notice, 
                  isLoading: true})     
              
            let formData = customer;
            const fd = new FormData();
            for(let k in formData){
            fd.append(k, formData[k])
            }
            if(image.passportFile!==''){
                fd.append('passportFile', image.passportFile)
            }
            let url = ServerUrl+'/save_controller.php?tablename=tbl_customers';
            axios.post(url, fd, config)
            .then(response =>{
            if(response.data.type === 'success'){
                Alerts('Success!', 'success', response.data.message)
                      } else{
                 
                    window.scrollTo(0,0)
              setErrors({...errors, errorMessage: JSON.stringify(response.data, null, 2)})
                      }   
                  })
                  .catch((error)=>{
                    setErrors({...errors, errorMessage: error.message})
                  }).finally(()=>{
                    setImage({
                        passport:'',
                        passportFile:''
                    })
                    setCustomer(Initials)
                    setNotice({...notice, 
                      isLoading: false
                    })
                    setIsSubmitting(false);
                  }) 
              }

              const ValidateExistence=()=>{
                if(customer.email!==''){
                       
                    const fd = new FormData();
                    fd.append("data", customer.email)
                    let url = ServerUrl+'/existence_controller.php?tablename=tbl_customers&column=email';
                    axios.post(url, fd, config).then(response=>{
                        if(response.data===true){
                            setErrors({...errors, email:customer.email + ' already register'})
                            setCustomer({...customer, email:''});
                            return '';
                        }
                  })
                }

                if(customer.accountNumber!==''){
                       
                    const fd = new FormData();
                    fd.append("data", customer.accountNumber)
                    let url = ServerUrl+'/existence_controller.php?tablename=tbl_customers&column=accountNumber';
                    axios.post(url, fd, config).then(response=>{
                        if(response.data===true){
                            setErrors({...errors, accountNumber:customer.accountNumber + ' already exist'})
                            setCustomer({...customer, accountNumber:''});
                            return '';
                        }
                  })
                }

                if(customer.onlineID!==''){
                       
                    const fd = new FormData();
                    fd.append("data", customer.onlineID)
                    let url = ServerUrl+'/existence_controller.php?tablename=tbl_customers&column=onlineID';
                    axios.post(url, fd, config).then(response=>{
                        if(response.data===true){
                            setErrors({...errors, onlineID:customer.onlineID + ' already exist'})
                            setCustomer({...customer, onlineID:''});
                            return '';
                        }
                  })
                }
            } 

         

    return (<Template 
    name='Add New Customer' 
    title='Use the form below to add new customer' 
    menu='Customers'
    menuLink='/customers'
    >

        
        {notice.isLoading?<Loader />:''}

        {errors.errorMessage?
<LabelWarning message ={errors.errorMessage} />:''}

                                
<div className="card" >
					<div className="card-header header-elements-inline">
						<h5 className="card-title">Create an Account</h5>
						<div className="header-elements">
							<div className="list-icons">
		                		<a className="list-icons-item" data-action="collapse"></a>
		                		<a className="list-icons-item" data-action="reload"></a>
		                		<a className="list-icons-item" data-action="remove"></a>
		                	</div>
	                	</div>
					</div>

					<div className="card-body">
						<form action="#">
							<div className="row">
								<div className="col-md-6">
									<fieldset>
										<legend className="font-weight-semibold"><i className="icon-reading mr-2"></i> Personal details</legend>

        <div className="form-group">
            <label>Customer fullname <span style={{color:'red'}}>*</span> :</label>
            <input type="text"  name="fullname" value={customer.fullname} placeholder="Enter customer fullname" onChange={handleChange} className={errors.fullname  ? 'form-control border-danger' : 'form-control'} /> 
            <label id="with_icon-error" className="validation-invalid-label" htmlFor="with_icon">{ errors.fullname}</label>

        </div>

        <div className="form-group">
        <label>Gender:</label><br/>
        <div className="form-check form-check-inline">
            <label className="form-check-label">
                <div className="uniform-choice">
                    <span className={customer.gender==='Male'?'checked':''}>
                        <input type="radio" className="form-input-styled" name="gender" onChange={handleChange} value='Male'  /></span></div>
                Male
            </label>
        </div>

        <div className="form-check form-check-inline">
            <label className="form-check-label">
                <div className="uniform-choice">
                    <span className={customer.gender==='Female'?'checked':''}>
                    <input type="radio" className="form-input-styled" name="gender" onChange={handleChange} value='Female'  />
                    
                    </span></div>
                Female
            </label>
        </div>
    
    </div>
    
        <div className="form-group">
            <label>Email Address <span style={{color:'red'}}>*</span> :</label>
            <input type="email" id="email" name="email"  onBlur={ValidateExistence} value={customer.email} placeholder="Customer email Address" onChange={handleChange} className={errors.email  ? 'form-control border-danger' : 'form-control'} />
            <label id="with_icon-error" className="validation-invalid-label" htmlFor="with_icon">{ errors.email}</label>
        </div>

        <div className="form-group">
            <label>Birth Date:</label>

        <input type="date"  name="birthDate"  value={customer.birthDate} placeholder="Customer Date of Birth" onChange={handleChange} className='form-control' /> 
        </div>

        <div className="form-group">
            <label>Telephone:</label>
            <input type="text"  name="telephone" value={customer.telephone} placeholder="Telephone Number" onChange={handleChange} className='form-control' /> 
        

        </div>



                                        <div className="form-group ">
    <label className="col-form-label">Upload Passport:</label>
    <div className="">
        <div className="media mt-0">
            <div className="mr-3">
                <a href="#">
                    <img src={image.passport} onError={(e)=>{e.target.onerror = null; e.target.src=PictureUrl+"/no.jpg"}} width="60" height="60" className="rounded-round" alt="" />
                </a>
            </div>

            <div className="media-body">
               {image.passport?	<button type="button" onClick={removeImageUrl} className="btn bg-pink-400"  > Remove Passport</button>	: <div className="uniform-uploader disabled">
                
                    <input type="file" className="form-input-styled" name="passport" id="passport"  onChange={handleFile('passport')} />
                    
                    <span className="filename" >No file selected</span>
                    <span className="action btn bg-pink-400" >Choose File</span></div>}
                <span className="form-text text-muted">Accepted formats: gif, png, jpg. Max file size 2Mb</span>
            </div>
        </div>
    </div>
</div>

        <div className="form-group">

        <label>Password <span style={{color:'red'}}>*</span>:</label>
        <input type="password" id="password" name="password"  value={customer.password} placeholder="Password" onChange={handleChange} className={errors.password  ? 'form-control border-danger' : 'form-control'} /> 
        <label id="with_icon-error" className="validation-invalid-label" htmlFor="with_icon">{ errors.password}</label>

                        </div>

                        <div className="form-group">
                            <label>Marital Status:</label>
                            <select  className="form-control " onChange={handleChange} name='maritalStatus' value={customer.maritalStatus} >
                                <option value="Single">Single</option>
                                <option value="Married">Married</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>

                        <div className="form-group">
                            <label>Address:</label>
                            <textarea rows="7" cols="5" onChange={handleChange} value={customer.address} name='address' className="form-control" placeholder="Customer Address">
                            {customer.address}
                            </textarea>
                        </div>



                        
                    </fieldset>
                </div>

    <div className="col-md-6">
        <fieldset>
            <legend className="font-weight-semibold"><i className="icon-office mr-2"></i> Bank details</legend>

            <div className="form-group">

                <label>Account Number <span style={{color:'red'}}>*</span>:</label>
                <div className="input-group">
                <input type="text"  name="accountNumber" value={customer.accountNumber} onBlur={ValidateExistence} placeholder="Enter account number or generate one" onChange={handleChange} className={errors.accountNumber  ? 'form-control border-danger' : 'form-control'} /> 

                <span className="input-group-append">
                    <span className="btn input-group-btn btn-info" onClick={generateCode} style={{cursor:'pointer'}}><i className="icon-cog"></i> Generate</span>
                </span>

            </div>
                
                <label id="with_icon-error" className="validation-invalid-label" for="with_icon">{errors.accountNumber}</label>

            </div>

            
            <div className="form-group">

                <label>Online ID <span style={{color:'red'}}>*</span>:</label>
                <div className="input-group">
                <input type="text"  name="onlineID" value={customer.onlineID} onBlur={ValidateExistence} placeholder="Enter online ID or generate one" onChange={handleChange} className={errors.onlineID  ? 'form-control border-danger' : 'form-control'} /> 

                <span className="input-group-append">
                    <span className="btn input-group-btn btn-info" onClick={generateID} style={{cursor:'pointer'}}><i className="icon-cog"></i> Generate</span>
                </span>

            </div>
                
                <label id="with_icon-error" className="validation-invalid-label" for="with_icon">{errors.onlineID}</label>

            </div>


            <div className="form-group">
                <label>Branch Code:</label>
                <input type="text"  name="branchCode" value={customer.branchCode} placeholder="Enter branch code" onChange={handleChange} className='form-control' /> 
            </div>

            <div className="form-group">
                <label>Account Type:</label>
                <select  className="form-control " onChange={handleChange} name='accountType' value={customer.accountType}>
                    <option value="Single Savings Account">Single Savings Account</option>
                    <option value="Current Account">Current Account</option>
                    <option value="Joint Account">Joint Account</option>
                </select>
            </div>



            <div className="form-group">
                <label>Opening Balance:</label>
                <input type="number"  name="accountBalance" value={customer.accountBalance} placeholder="Enter opening balance" onChange={handleChange} className='form-control' /> 
            </div>

        
            <div className="form-group">
            <label>Account Status:</label><br/>
            <div className="form-check form-check-inline">
                <label className="form-check-label">
                    <div className="uniform-choice">
                        <span className={customer.accountStatus==='Active'?'checked':''}>
                            <input type="radio" className="form-input-styled" name="accountStatus" onChange={handleChange} value='Active'  /></span></div>
                    Active
                </label>
            </div>

            <div className="form-check form-check-inline">
                <label className="form-check-label">
                    <div className="uniform-choice">
                        <span className={customer.accountStatus==='Inactive'?'checked':''}>
                        <input type="radio" className="form-input-styled" name="accountStatus" onChange={handleChange} value='Inactive'  />
                        
                        </span></div>
                    Inactive
                </label>
            </div>
            </div>

            <div className="form-group">
                <label>Next of Kin Name:</label>
                <input type="text"  name="nokin_name" value={customer.nokin_name} placeholder="Next of Kin Name" onChange={handleChange} className='form-control' /> 
            </div>

            <div className="form-group">
                <label>Next of Kin Telephone:</label>
                <input type="text"  name="nokin_phone" value={customer.nokin_phone} placeholder="Telephone" onChange={handleChange} className='form-control' /> 
            </div>


            <div className="form-group">
                <label>Next of Kin Relationship:</label>
                <input type="text"  name="nokin_relationship" value={customer.nokin_relationship} placeholder="Relationship" onChange={handleChange} className='form-control' /> 
                
            </div>


            <div className="form-group">
                <label>Next of Kin Address:</label>
                <textarea rows="5" cols="5" onChange={handleChange} value={customer.nokin_address} name='nokin_address' className="form-control" placeholder="Next of Kin Address">
                {customer.nokin_address}
                </textarea>
            </div>



									</fieldset>
								</div>
							</div>

							<div className="text-center">
								<button type="button" id="submitForm" onClick={handleSubmit} className="btn btn-primary"><i className="icon-paperplane ml-2"></i> Create Account </button>
							</div>
						</form>
					</div>
				</div>




</Template>
);
       
}

export default React.memo(CreateAccount)  
