import React, {useState, useEffect} from 'react'

import { Token, config, ServerUrl, staffCode, PictureUrl } from './include';
import { Alerts } from './notify';
import axios from 'axios'
import Cookies from 'js-cookie'

const Sidebar = (props)=>{
    const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
    
 
    const [users, setUsers] = useState([])
    const fetchContent =()=>{
        if(staffCode!=='null'){
    
        var sql ="Select passport, 	fullname from tbl_user where code ='"+staffCode+"'";
         
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token);
        let url = ServerUrl+'/fetchBySql_controller.php'
        
        axios.post(url, fd, config).then(result=>{ 
            if(result.data.length!==0){
                setUsers(result.data[0])
            }else{
                window.location.href='/' 
            }
            
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
        
        }else{
            window.location.href='/'
        }
    }
    
    
    
    useEffect(()=>{
       fetchContent() 
        },[]);

    return  <div className="sidebar sidebar-dark sidebar-main sidebar-fixed sidebar-expand-md">

       
    <div className="sidebar-mobile-toggler text-center">
        <a href="#" className="sidebar-mobile-main-toggle">
            <i className="icon-arrow-left8"></i>
        </a>
        Navigation
        <a href="#" className="sidebar-mobile-expand">
            <i className="icon-screen-full"></i>
            <i className="icon-screen-normal"></i>
        </a>
    </div>
   
    <div className="sidebar-content">

      
        <div className="sidebar-user">
            <div className="card-body">
                <div className="media">
                    <div className="mr-3">
                        <a href="#"><img src={PictureUrl +'/customers/'+users.passport} onError={(e)=>{e.target.onerror = null; e.target.src=PictureUrl+"/customers/Male.png"}} width="50" height="50" className="rounded-circle" alt="" /></a>
                    </div>

                    <div className="media-body">
                        <div className="media-title font-weight-semibold">{users.fullname}</div>
                        <div className="font-size-xs opacity-50">
                            <i className="icon-pin font-size-sm"></i> &nbsp;Santa Ana, CA
                        </div>
                    </div>

                    <div className="ml-3 align-self-center">
                        <a href="#" className="text-white"><i className="icon-cog3"></i></a>
                    </div>
                </div>
            </div>
        </div>
       

        <div className="card card-sidebar-mobile">
            <ul className="nav nav-sidebar" data-nav-type="accordion">

               
                <li className="nav-item-header"><div className="text-uppercase font-size-xs line-height-xs"></div> <i className="icon-menu" title="Main"></i></li>
               
                <li className="nav-item">
                    <a href="/dashboard" className={`nav-link  ${props.name==='Dashboard'?'active':''}`}>
                        <i className="icon-home4"></i>
                        <span>
                            Dashboard
                        </span>
                    </a>
                </li>


                <li className="nav-item nav-item-submenu">
                    <a href="#" className={`nav-link  ${props.menu==='Customers'|| props.name==='Customers' ?'active':''}`}><i className="icon-users"></i> <span>Customers</span></a>

                    <ul className="nav nav-group-sub" data-submenu-title="Layouts">
                        <li className="nav-item"><a href="/customers" className={`nav-link  ${props.name==='Customers'?'active':''}`}>View All Customers</a></li>
                        <li className="nav-item"><a href="/customer/create" className={`nav-link  ${props.name==='Add New Customer'?'active':''}`}>Create New Account</a></li>

                        <li className="nav-item"><a href="/customers/transactions" className={`nav-link  ${props.name==='Transactions'?'active':''}`}>Customer Transactions</a></li>
                        
                    </ul>
                </li>
               
                <li className="nav-item">
                    <a href="/customers/pins" className={`nav-link  ${props.name==='Transaction PIN'?'active':''}`}>
                        <i className="icon-key"></i>
                        <span>Pin</span>
                    </a>
                </li>

                <li className="nav-item-header"><div className="text-uppercase font-size-xs line-height-xs">System</div> <i className="icon-menu" title="Main"></i></li>

                <li className="nav-item"><a href="/settings" className="nav-link"><i className="icon-wrench3"></i> <span>Settings</span></a></li>
              
                <li className="nav-item"><a href="/" className="nav-link"><i className="icon-exit"></i> <span>Log Out</span></a></li>

            </ul>
        </div>
        

    </div>
   
    
</div>
}
export default React.memo(Sidebar)