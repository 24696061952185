import React, {useState, useEffect} from 'react'
import Template from '../components/template';
import {Alerts, LabelWarning, WarningModal} from '../components/notify'
import {ServerUrl,  config,  PictureUrl, } from '../components/include'
import axios from 'axios'
import { Loader, TableLoader } from '../components/loader';

import Select from 'react-select'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, ColumnToggle } from 'react-bootstrap-table2-toolkit';
import { FormatNumber } from '../components/globalFunction';

const Transactions =()=> {

	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});

	const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    const [errors, setErrors] = useState({});
    const [customer, setCustomer] = useState([])     
    const [content, setContent] = useState([])
    const [search, setSearch]= useState({
        fromDate:'',
        toDate:'',
        customer:'',
        accountStatus:'all'
    })


    const fetchCustomer =()=>{
        setNotice({...notice, 
            isDataFetching: true
        });
       
        var sql ="Select * from tbl_customers " ;
        const fd = new FormData();
        fd.append("sql", sql);
        //fd.append("jwt", Token);
        let url = ServerUrl+'/fetchBySql_controller.php';
        
        axios.post(url, fd, config).then(result=>{  
            setCustomer(result.data)
        })
        .catch((error)=>{
            setErrors({...errors, errorMessage: error.message})
        }).finally(()=>{
            setNotice({...notice, 
                isDataFetching: false
            });
        }) 

  }

    const SearchTable =(event)=>{
        event.preventDefault();
        window.scrollTo({top:document.body.scrollHeight, left:0, behavior:'smooth'})
        fetchContent();
     }

    const fetchContent =()=>{
        setNotice({...notice, 
            isDataFetching: true
        });
       

        var sql ="Select t.ID, t.code, c.fullName, c.accountNumber,	t.dateTime, t.dateCreate,	t.bankIPOS, t.amount,	t.description, t.status, t.ref, t.bankName,	t.bankAccount, t.benefName,	t.swiftCode, t.customerCode from tbl_customer_transaction t, tbl_customers c where t.customerCode = c.code order by t.ID DESC " ;

        if(search.fromDate!=='' && search.toDate!=='' ){
            sql = sql + ' and t.dateCreate between "'+search.fromDate+'" and "'+search.toDate+'"'
        }

        
        if(search.customer.length !==0){
            sql = sql + '  and 	t.accountNumber = "'+search.customer.value+'"'
        }


        if(search.accountStatus!=='all'){
            sql = sql + '  and t.accountStatus = "'+search.accountStatus+'"'
        }
          
        const fd = new FormData();
        fd.append("sql", sql);
        //fd.append("jwt", Token);
        let url = ServerUrl+'/fetchBySql_controller.php';
        
        axios.post(url, fd, config).then(result=>{  
            setContent(result.data)

            
        })
        .catch((error)=>{
            setErrors({...errors, errorMessage: error.message})
        }).finally(()=>{
            setNotice({...notice, 
                isDataFetching: false
            });
        }) 

  }
  

  const handleChange =(event)=>{
    let {name, value} = event.target;
    setSearch({...search, [name] : value });
}


const handleSelect = (option, action)=>{
    setSearch({...search, [action.name] : option });
     }

const tableHeader = [

{dataField: 'fullName', text: 'Fullname & Account No', editable: false, formatter:(cell, row)=><span >{cell}<br/><b>{row.accountNumber}</b></span>},
{dataField: 'amount', text: 'Amount', formatter:(cell, row)=><span >{FormatNumber(cell)}</span>},
{dataField: 'dateTime', text: 'Date'},

{dataField: 'bankIPOS', text: 'Bank IPOS', hidden:true},

{dataField: 'description', text: 'Description'},

{dataField: 'ref', text: 'Ref', hidden:true},

{dataField: 'bankName', text: 'Bank Name'},

{dataField: 'benefName', text: 'Beneficiary'},

{dataField: 'swiftCode', text: 'Switf Code', hidden:true},

{dataField: 'bankAccount', text: 'Account No'},
 
{dataField: 'status', text: 'Status', sort: true, editor: {
    type: Type.SELECT, options:[{value:'Processing', label:'Processing'},
    {value:'Completed', label:'Completed'},
    {value:'Received', label:'Received'}]
  }, formatter:(cell)=>cell==='Completed'? 
  <span className="badge bg-success badge-pill">{cell}</span>:cell==='Received'?
  <span className="badge bg-info badge-pill">{cell}</span>:<span className="badge bg-danger badge-pill">{cell}</span>},

 {dataField: 'ID', text: 'Action', formatter: (cell, row)=><div >

 <WarningModal  handleAction={(e)=>handleDelete(e, row)} mID={row.ID} />
 <a href="#!" className="btn btn-danger btn-sm " data-toggle="modal"   data-target={`#warningdialog-${row.ID}`}><i className="icon-trash"></i> Delete</a>
 </div>}
 ];

 
 const handleDelete =(e, row)=>{
    e.preventDefault()
  let close =   document.getElementById('btnWarningDialog-'+row.ID)
  close.click();
  
  setNotice({...notice, 
      isLoading: true}) 

  const fd = new FormData();
  fd.append('ID', 'code')
  fd.append('data',row.code)
  //fd.append('jwt', Token)
let url = ServerUrl+'/delete_controller.php?tablename=tbl_customer_transaction';
    axios.post(url, fd, config)
    .then(response =>{
     if(response.data.type ==='success'){
        Alerts('Saved!', 'success', response.data.message)
      
            } else{
        Alerts('Error!', 'danger', response.data)
            }   
    })
    .catch((error)=>{
      Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        fetchContent()
        setNotice({...notice, 
            isLoading: false
        })
    }) 
  
  
}

									
 const TableRecord=(props)=>{

    const CustomToggleList = ({
        columns,
        onColumnToggle,
        toggles
      }) => ( 
      <div  className="btn-group btn-group-toggle btn-group-vertical" data-toggle="buttons">
          {
            columns
              .map(column => ({
                ...column,
                toggle: toggles[column.dataField]
              }))
              .map(column => (
                
                <button
                  type="button"
                  key={ column.dataField }
                  className={ `  ${column.toggle ? 'btn btn-success' : 'btn btn-default'}` }
                  data-toggle="button"
                  aria-pressed={ column.toggle ? 'true' : 'false' }
                  onClick={ () => onColumnToggle(column.dataField) }
                >
                  { column.text }
                </button>
              ))
          }
        </div>
        
      );

    const handleUpdate=(column, newValue, ID)=>{       
        const fd = new FormData(); 
            fd.append('newValue', newValue);
            fd.append('column', column.dataField);
            fd.append('ID', ID);
           // fd.append("jwt", Token);
           let url = ServerUrl+'/updateCustom_controller.php?tablename=tbl_customer_transaction'
          axios.post(url, fd, config)
          .then(result => console.log(result.data))
          .then()
          .catch((error)=>Alerts('Error!', 'danger', error.message)) 
        } 
     const { SearchBar } = Search;
     const customTotal = (from, to, size) => (
        <span >&nbsp;Showing { from } to { to } of { size } items</span>
      );
     const options = {
        showTotal: true,
      paginationTotalRenderer: customTotal,
      sizePerPageList: [{text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100}, {text: '500', value: 500}, {text: '1000', value: 1000},
      { text: 'All', value: props.data.length }]
       
      };
     return  <ToolkitProvider search columnToggle 
                 keyField='ID' data={ props.data } columns={ props.columns } >
                     
                     {
                         props => (
                         <div >
                           <div className="form-group">
                             <SearchBar
                             { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                             
                             <div className="btn-group dropdown-split-success pull-right">
      
      <button type="button" className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i className="fa fa-search-plus"></i> Columns Visibility
      </button>
      
      <div className="dropdown-menu" x-placement="bottom-start" style={{overflow:'scroll', height:'260px', width:'150px', position: 'absolute', transform: 'translate3d(113px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
        <CustomToggleList { ...props.columnToggleProps } />
      </div>
      </div>
      </div>

                             
                             
                 <BootstrapTable
                             { ...props.baseProps }
                                         striped
                                     hover
                                     condensed
                                     noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                 pagination={paginationFactory(options) }
                                 cellEdit={ cellEditFactory({
                                    mode: 'dbclick',
                                    blurToSave: true,
                                    autoSelectText: true,
                          beforeSaveCell: (oldValue, newValue, row, column) => {
                            if(oldValue !==newValue){
                            handleUpdate(column, newValue, row.ID);
                            
                           return '';
                          }
                          
                        }
                                  }) }
                             />
                         </div>
                         )
                     }
  </ToolkitProvider>
  }
  





 useEffect(()=>{
    fetchContent()
    fetchCustomer()
   },[]);

    return (<Template 
    name='Transactions' 
    title='All customers transactions' 
    menu='Customers'
    menuLink='/customers'
    >

        
        {notice.isLoading?<Loader />:''}

        {errors.errorMessage?
<LabelWarning message ={errors.errorMessage} />:''}


<div className="card" >
					<div className="card-header header-elements-inline">
						<h5 className="card-title">Search Transactions</h5>
						<div className="header-elements">
							<div className="list-icons">
		                		<a className="list-icons-item" data-action="collapse"></a>
		                		<a className="list-icons-item" data-action="reload"></a>
		                		<a className="list-icons-item" data-action="remove"></a>
		                	</div>
	                	</div>
					</div>

					<div className="card-body">
						

                    <div className="row">
								<div className="col-md-6">
									<fieldset>
										

        <div className="form-group">
            <label>From Date:</label>
            <input type="date"  name="fromDate" value={search.fromDate} placeholder="From Date" onChange={handleChange} className='form-control' /> 

        </div>

        <div className="form-group">
            <label>Select Customer:</label>
            <Select   options={customer.map((data, idx)=> {
                                    return {key:idx, value: data.code, label: data.fullname };
                                  })}
onChange={handleSelect}  name="customer" value={search.customer} styles={selectStyles} /> 

        </div>


        </fieldset>
                </div>

    <div className="col-md-6">
        <fieldset>


        <div className="form-group">
            <label>To Date:</label>
            <input type="date"  name="toDate" value={search.toDate} placeholder="To Date" onChange={handleChange} className='form-control' /> 

        </div>

        <div className="form-group">
                <label>Status:</label>
                <select  className="form-control " onChange={handleChange} name='accountStatus' value={search.accountStatus}>
                    <option value="all">All</option>
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                </select>
            </div>

        </fieldset>
                </div>

                </div>

                <div className="row"> 
            
            <div className="col-md-12 text-center">
           
	<button type="button" className="btn btn-success "  onClick={SearchTable}><i className="icon-search4"></i> Search</button>
    </div>
            
            </div>



					</div>
				</div>


                                
<div className="card" >
					<div className="card-header header-elements-inline">
						<h5 className="card-title">All Transaction</h5>
						<div className="header-elements">
							<div className="list-icons">
		                		<a className="list-icons-item" data-action="collapse"></a>
		                		<a className="list-icons-item" data-action="reload"></a>
		                		<a className="list-icons-item" data-action="remove"></a>
		                	</div>
	                	</div>
					</div>

					<div className="card-body">
						

                       <div className="col-md-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
        <TableRecord data={content}  columns={tableHeader}  />}
            </div>
					</div>
				</div>




</Template>
);
       
}

export default React.memo(Transactions)  
