
import Cookies from 'js-cookie'
var CryptoJS = require('crypto-js')


export const staffCode = Cookies.get('rbcd')?CryptoJS.AES.decrypt(Cookies.get('rbcd'), 'royal_bank').toString(CryptoJS.enc.Utf8):'null'

export const Token = Cookies.get('rbtk')?CryptoJS.AES.decrypt(Cookies.get('rbtk'), 'royal_bank').toString(CryptoJS.enc.Utf8):'null'


export const config = {
    headers: { 'content-type': 'multipart/form-data'}
} 


/* 
   export  const ServerUrl = 'http://192.168.64.3/React/equitybank/backadmin/controller';
export  const PictureUrl = 'http://192.168.64.3/React/equitybank/backadmin/images';
export const DatabaseName = "royalbandk_db"  
export  const FrontEndUrl = 'http://192.168.64.3/React/equitybank/backadmin';  */ 



export  const ServerUrl = 'https://backadmin.equityfinancialbank.com/controller';
export  const PictureUrl = 'https://backadmin.equityfinancialbank.com/images';
export const DatabaseName = "equitybank_db"  
export  const FrontEndUrl = 'https://backadmin.equityfinancialbank.com';   




