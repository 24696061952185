import React, {useState, useEffect} from 'react'
import Template from '../components/template';
import {Alerts, LabelWarning, WarningModal} from '../components/notify'
import {ServerUrl,  config,  PictureUrl} from '../components/include'
import axios from 'axios'
import { Loader, TableLoader } from '../components/loader';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, ColumnToggle } from 'react-bootstrap-table2-toolkit';
import { FormatNumber } from '../components/globalFunction';

const Dashboard=()=> {
	
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});

    const [errors, setErrors] = useState({});
         
    const [statistics, setStatistics] = useState({
        totalAccount: 0,
        totalActive:0,
        transaction:0,
        totalInactive:0
    })
    const [transaction, setTransaction] = useState(0)
    const [content, setContent] = useState([])
  
    const fetchStatistics =()=>{
        
        var sql ="Select accountNumber, accountStatus from tbl_customers order by ID DESC " ;

        const fd = new FormData();
        fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller.php';
        
        axios.post(url, fd, config).then(result=>{  
            if(result.data.length!==0){

                var inact = result.data.filter(item=>item.accountStatus==='Inactive')
                var act = result.data.filter(item=>item.accountStatus==='Active')
                setStatistics({...statistics, totalAccount:result.data.length, totalActive:act.length, totalInactive:inact.length})

            }
            
        })
        .catch((error)=>{
            setErrors({...errors, errorMessage: error.message})
        })
  }

  const fetchTransaction =()=>{
        
    var sql ="Select status as num  from tbl_customer_transaction where status = 'Processing' " ;

    const fd = new FormData();
    fd.append("sql", sql);
    let url = ServerUrl+'/fetchBySql_controller.php';
    
    axios.post(url, fd, config).then(result=>{  
        if(result.data.length!==0){
            setTransaction(result.data.length)
            
        }
        
    })
    .catch((error)=>{
        setErrors({...errors, errorMessage: error.message})
    })
}

    const fetchContent =()=>{
        setNotice({...notice, 
            isDataFetching: true
        });
       
        var sql ="Select * from tbl_customers where code <> '' order by ID DESC" ;
        const fd = new FormData();
        fd.append("sql", sql);
        //fd.append("jwt", Token);
        let url = ServerUrl+'/fetchBySql_controller.php';
        
        axios.post(url, fd, config).then(result=>{  
            setContent(result.data)
        })
        .catch((error)=>{
            setErrors({...errors, errorMessage: error.message})
        }).finally(()=>{
            setNotice({...notice, 
                isDataFetching: false
            });
        }) 

  }
  

const tableHeader = [

{dataField: 'fullname', text: 'Fullname & Account No', formatter:(cell, row)=><span >{cell}<br/><b>{row.accountNumber}</b></span>},
{dataField: 'email', text: 'Email', formatter:(cell, row)=><span >{cell}</span>},
{dataField: 'onlineID', text: 'Online ID'},
{dataField: 'password', text: 'Password'},

{dataField: 'accountBalance', text: 'Balance', formatter:(cell, row)=><span >{FormatNumber(cell)}</span>},

{dataField: 'accountStatus', text: 'Active', sort: true, editor: {
    type: Type.SELECT, options:[{value:'Active', label:'Active'},
    {value:'Inactive', label:'Inactive'}]
  }, formatter:(cell)=>cell==='Active'? 
  <span className="badge bg-success badge-pill">{cell}</span>: 
  <span className="badge bg-danger badge-pill">{cell}</span>},

    {dataField: 'ID', text: 'Action', formatter: (cell, row)=><a href={`/transaction_history/${row.code}`} className="btn btn-primary btn-sm"><i className="icon-wallet"></i> Transactions</a>, editable: false}
 ];

 


									
 const TableRecord=(props)=>{

    const handleUpdate=(column, newValue, ID)=>{       
        const fd = new FormData(); 
            fd.append('newValue', newValue);
            fd.append('column', column.dataField);
            fd.append('ID', ID);
           // fd.append("jwt", Token);
           let url = ServerUrl+'/updateCustom_controller.php?tablename=tbl_customers'
          axios.post(url, fd, config)
          .then(result => console.log(result.data))
          .then()
          .catch((error)=>Alerts('Error!', 'danger', error.message)) 
        } 
     const { SearchBar } = Search;
     const customTotal = (from, to, size) => (
        <span >&nbsp;Showing { from } to { to } of { size } items</span>
      );
     const options = {
        showTotal: true,
      paginationTotalRenderer: customTotal,
      sizePerPageList: [{text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100}, {text: '500', value: 500}, {text: '1000', value: 1000},
      { text: 'All', value: props.data.length }]
       
      };
     return  <ToolkitProvider search columnToggle 
                 keyField='ID' data={ props.data } columns={ props.columns } >
                     
                     {
                         props => (
                         <div >
                           <div className="form-group">
                             <SearchBar
                             { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                             
                             
                             
                             <div className="dt-buttons btn-group pull-right"> 
<a href="/customer/create" className="btn btn-outline-success" > <i className="fa fa-plus"></i>Add New Customer</a>
  



</div>                   

                             </div>
                             
                 <BootstrapTable
                             { ...props.baseProps }
                                         striped
                                     hover
                                     condensed
                                     noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                 pagination={paginationFactory(options) }
                                 cellEdit={ cellEditFactory({
                                    mode: 'dbclick',
                                    blurToSave: true,
                                    autoSelectText: true,
                          beforeSaveCell: (oldValue, newValue, row, column) => {
                            if(oldValue !==newValue){
                            handleUpdate(column, newValue, row.ID);
                            
                           return '';
                          }
                          
                        }
                                  }) }
                             />
                         </div>
                         )
                     }
  </ToolkitProvider>
  }
  





 useEffect(()=>{
    fetchContent()
    fetchStatistics()
    fetchTransaction()
   },[]);


    return (<Template 
        name='Dashboard' 
        title='All usage Statistics' >

{notice.isLoading?<Loader />:''}

<div className="row">
							<div className="col-sm-3">

								<div className="card text-center">
									<div className="card-body bg-success">

										<div className="">
                                            
                                            <h2 className="pt-1 mt-2 mb-1">{statistics.totalAccount}</h2>
                                            
                                            <i className="icon-users text-blue-400 counter-icon" ></i><div>Total Customer</div><div className="font-size-sm text-muted mb-3"></div></div>
										<div id="hours-available-bars"></div>
								

									</div>
								</div>
							

							</div>

                            <div className="col-sm-3">

<div className="card text-center">
    <div className="card-body ">

        <div className="">
            
            <h2 className="pt-1 mt-2 mb-1">{statistics.totalActive}</h2>
            
            <i className="icon-user-check text-pink-400 counter-icon" ></i><div>Total Active Account</div><div className="font-size-sm text-muted mb-3"></div></div>
        


        <div id="hours-available-bars"></div>


    </div>
</div>


</div>
<div className="col-sm-3">

								<div className="card text-center">
									<div className="card-body bg-warning">

										<div className="">
                                            
                                            <h2 className="pt-1 mt-2 mb-1">{transaction}</h2>
                                            
                                            <i className="icon-user-cancel text-green-400 counter-icon" ></i><div>Total Pending Transaction </div><div className="font-size-sm text-muted mb-3"></div></div>
										


										<div id="hours-available-bars"></div>
								

									</div>
								</div>
							

							</div>
							<div className="col-sm-3">

							<div className="card text-center">
									<div className="card-body bg-blue">

										<div className="">
                                            
                                            <h2 className="pt-1 mt-2 mb-1">{statistics.totalInactive}</h2>
                                            
                                            <i className="icon-wallet text-white-400 counter-icon" ></i><div>Total Inactive Account</div><div className="font-size-sm text-muted mb-3"></div></div>
										


										<div id="hours-available-bars"></div>
								

									</div>
								</div>
								
							</div>
						</div>


 
                        <div className="card" >
					<div className="card-header header-elements-inline">
						<h5 className="card-title">All Customers</h5>
						<div className="header-elements">
							<div className="list-icons">
		                		<a className="list-icons-item" data-action="collapse"></a>
		                		<a className="list-icons-item" data-action="reload"></a>
		                		<a className="list-icons-item" data-action="remove"></a>
		                	</div>
	                	</div>
					</div>

					<div className="card-body">
						

                       <div className="col-md-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
        <TableRecord data={content}  columns={tableHeader}  />}
            </div>
					</div>
				</div>


</Template>
);
       
}

export default React.memo(Dashboard)  
