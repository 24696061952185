import React, {useState} from 'react'

const scrollTop =()=>{
    window.scrollTo({top:0, left:document.body.scrollHeight, behavior:'smooth'})
}
const Footer = ()=>{
    
    const [displayScroll, setDisplayScroll] = useState(false)
    window.addEventListener('scroll', function(event){
        if(this.scrollY>300){
            setDisplayScroll(true)
        }else{
            setDisplayScroll(false)
        }
    })
    return   <div className="navbar navbar-expand-lg navbar-light">
<div className="text-center d-lg-none w-100">
    <button type="button" className="navbar-toggler dropdown-toggle" data-toggle="collapse" data-target="#navbar-footer">
        <i className="icon-unfold mr-2"></i>
        Footer
    </button>
</div>

    {displayScroll?<button type="button" onClick={scrollTop} className="btn bg-success-400 btn-float rounded-round back-to-top"><i className="icon-rating3 "></i></button> :''}

<div className="navbar-collapse collapse" id="navbar-footer">
    <span className="navbar-text">
        &copy; {new Date().getFullYear()} <a href="#">Equity Financial Bank</a> 
    </span>

    <ul className="navbar-nav ml-lg-auto">
        <li className="nav-item"><a href="#!" className="navbar-nav-link" target="_blank"><i className="icon-lifebuoy mr-2"></i> Support</a></li>
        <li className="nav-item"><a href="#!" className="navbar-nav-link" target="_blank"><i className="icon-file-text2 mr-2"></i> Docs</a></li>
        <li className="nav-item"><a href="#!" className="navbar-nav-link font-weight-semibold"><span className="text-pink-400"><i className="icon-bubble-dots4 mr-2"></i> Chat Online</span></a></li>
    </ul>
</div>
</div>
}
export default React.memo(Footer)