import React, {useState, useEffect} from 'react'
import Template from '../components/template';
import {Alerts, LabelWarning, WarningModal} from '../components/notify'
import {ServerUrl,  config,  PictureUrl} from '../components/include'
import axios from 'axios'
import { Loader, TableLoader } from '../components/loader';


import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, ColumnToggle } from 'react-bootstrap-table2-toolkit';
import { FormatNumber } from '../components/globalFunction';

const Customers =()=> {

	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});

    const [errors, setErrors] = useState({});
         
    const [content, setContent] = useState([])
    const [search, setSearch]= useState({
        fromDate:'',
        toDate:'',
        acctNo:'',
        accountStatus:'all'
    })

    const SearchTable =(event)=>{
        event.preventDefault();
        window.scrollTo({top:document.body.scrollHeight, left:0, behavior:'smooth'})
        fetchContent();
     }

    const fetchContent =()=>{
        setNotice({...notice, 
            isDataFetching: true
        });
       
        var sql ="Select * from tbl_customers where code <> '' " ;

        if(search.fromDate!=='' && search.toDate!=='' ){
            sql = sql + ' and dateCreate between "'+search.fromDate+'" and "'+search.toDate+'"'
        }

        
        if(search.acctNo !==''){
            sql = sql + '  and 	accountNumber = "'+search.acctNo+'"'
        }


        if(search.accountStatus!=='all'){
            sql = sql + '  and accountStatus = "'+search.accountStatus+'"'
        }
          
        const fd = new FormData();
        fd.append("sql", sql);
        //fd.append("jwt", Token);
        let url = ServerUrl+'/fetchBySql_controller.php';
        
        axios.post(url, fd, config).then(result=>{  
            setContent(result.data)

            
        })
        .catch((error)=>{
            setErrors({...errors, errorMessage: error.message})
        }).finally(()=>{
            setNotice({...notice, 
                isDataFetching: false
            });
        }) 

  }
  
  const handleDelete =(e, row)=>{
      e.preventDefault()
    let close =   document.getElementById('btnWarningDialog-'+row.ID)
    close.click();
    
    setNotice({...notice, 
        isLoading: true}) 

    const fd = new FormData();
    fd.append('ID', 'code')
    fd.append('data',row.code)
    //fd.append('jwt', Token)
let url = ServerUrl+'/delete_controller.php?tablename=tbl_customers';
      axios.post(url, fd, config)
      .then(response =>{
       if(response.data.type ==='success'){
          Alerts('Saved!', 'success', response.data.message)
        
              } else{
          Alerts('Error!', 'danger', response.data)
              }   
      })
      .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
      }).finally(()=>{
          fetchContent()
          setNotice({...notice, 
              isLoading: false
          })
      }) 
    
    
}

  const handleChange =(event)=>{
    let {name, value} = event.target;
    setSearch({...search, [name] : value });
}


const tableHeader = [
    {dataField: 'passport', text: 'Passport', editable: false, formatter:(cell, row)=><img id={cell} height="60" onError={(e)=>{e.target.onerror = null; e.target.src=PictureUrl+"/"+row.gender+".png"}} src={`${PictureUrl}/customers/${cell}`} title={row.fullname}  alt={row.fullname} />},

{dataField: 'fullname', text: 'Fullname & Account No', formatter:(cell, row)=><span >{cell}<br/><b>{row.accountNumber}</b></span>},
{dataField: 'email', text: 'Email', formatter:(cell, row)=><span >{cell}</span>},
{dataField: 'onlineID', text: 'Online ID'},
{dataField: 'password', text: 'Password'},

{dataField: 'accountBalance', text: 'Balance', formatter:(cell, row)=><span >{FormatNumber(cell)}</span>},

{dataField: 'accountStatus', text: 'Active', sort: true, editor: {
    type: Type.SELECT, options:[{value:'Active', label:'Active'},
    {value:'Inactive', label:'Inactive'}]
  }, formatter:(cell)=>cell==='Active'? 
  <span className="badge bg-success badge-pill">{cell}</span>: 
  <span className="badge bg-danger badge-pill">{cell}</span>},

    {dataField: 'ID', text: 'Action', formatter: (cell, row)=><div className="list-icons">

<WarningModal  handleAction={(e)=>handleDelete(e, row)} mID={row.ID} />

    <div className="dropdown">
        <a href="#" className="list-icons-item" data-toggle="dropdown" aria-expanded="false"><i className="icon-menu7"></i></a>

        <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-end" >
            

            <a href={`/customer/edit/${row.code}`} className="dropdown-item"><i className="icon-user"></i> Edit Customer</a>

            <a href="#" className="dropdown-item"><i className="icon-key"></i> Login As this Customer</a>
            <a href={`/transaction_history/${row.code}`} className="dropdown-item"><i className="icon-wallet"></i> Transaction History</a>
            <div className="dropdown-divider"></div>

            <a href="#!" className="dropdown-item" data-toggle="modal"   data-target={`#warningdialog-${row.ID}`}><i className="icon-trash"></i> Delete Customer</a>
        </div>
    </div>
</div>, editable: false}
 ];

 


									
 const TableRecord=(props)=>{

    const handleUpdate=(column, newValue, ID)=>{       
        const fd = new FormData(); 
            fd.append('newValue', newValue);
            fd.append('column', column.dataField);
            fd.append('ID', ID);
           // fd.append("jwt", Token);
           let url = ServerUrl+'/updateCustom_controller.php?tablename=tbl_customers'
          axios.post(url, fd, config)
          .then(result => console.log(result.data))
          .then()
          .catch((error)=>Alerts('Error!', 'danger', error.message)) 
        } 
     const { SearchBar } = Search;
     const customTotal = (from, to, size) => (
        <span >&nbsp;Showing { from } to { to } of { size } items</span>
      );
     const options = {
        showTotal: true,
      paginationTotalRenderer: customTotal,
      sizePerPageList: [{text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100}, {text: '500', value: 500}, {text: '1000', value: 1000},
      { text: 'All', value: props.data.length }]
       
      };
     return  <ToolkitProvider search columnToggle 
                 keyField='ID' data={ props.data } columns={ props.columns } >
                     
                     {
                         props => (
                         <div >
                           <div className="form-group">
                             <SearchBar
                             { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                             
                             
                             
                             <div className="dt-buttons btn-group pull-right"> 
<a href="/customer/create" className="btn btn-outline-success" > <i className="fa fa-plus"></i>Add New Customer</a>
  



</div>                   

                             </div>
                             
                 <BootstrapTable
                             { ...props.baseProps }
                                         striped
                                     hover
                                     condensed
                                     noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                 pagination={paginationFactory(options) }
                                 cellEdit={ cellEditFactory({
                                    mode: 'dbclick',
                                    blurToSave: true,
                                    autoSelectText: true,
                          beforeSaveCell: (oldValue, newValue, row, column) => {
                            if(oldValue !==newValue){
                            handleUpdate(column, newValue, row.ID);
                            
                           return '';
                          }
                          
                        }
                                  }) }
                             />
                         </div>
                         )
                     }
  </ToolkitProvider>
  }
  





 useEffect(()=>{
    fetchContent()
   },[]);

    return (<Template 
    name='Customers' 
    title='All registered customers' 
    >

        
        {notice.isLoading?<Loader />:''}

        {errors.errorMessage?
<LabelWarning message ={errors.errorMessage} />:''}


<div className="card" >
					<div className="card-header header-elements-inline">
						<h5 className="card-title">Search Customers</h5>
						<div className="header-elements">
							<div className="list-icons">
		                		<a className="list-icons-item" data-action="collapse"></a>
		                		<a className="list-icons-item" data-action="reload"></a>
		                		<a className="list-icons-item" data-action="remove"></a>
		                	</div>
	                	</div>
					</div>

					<div className="card-body">
						

                    <div className="row">
								<div className="col-md-6">
									<fieldset>
										

        <div className="form-group">
            <label>From Date:</label>
            <input type="date"  name="fromDate" value={search.fromDate} placeholder="From Date" onChange={handleChange} className='form-control' /> 

        </div>

        <div className="form-group">
            <label>Account Number:</label>
            <input type="text"  name="acctNo" value={search.acctNo} placeholder="Account Number" onChange={handleChange} className='form-control' /> 

        </div>


        </fieldset>
                </div>

    <div className="col-md-6">
        <fieldset>


        <div className="form-group">
            <label>To Date:</label>
            <input type="date"  name="toDate" value={search.toDate} placeholder="To Date" onChange={handleChange} className='form-control' /> 

        </div>

        <div className="form-group">
                <label>Account Status:</label>
                <select  className="form-control " onChange={handleChange} name='accountStatus' value={search.accountStatus}>
                    <option value="all">All</option>
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                </select>
            </div>

        </fieldset>
                </div>

                </div>

                <div className="row"> 
            
            <div className="col-md-12 text-center">
           
	<button type="button" className="btn btn-success "  onClick={SearchTable}><i className="icon-search4"></i> Search</button>
    </div>
            
            </div>



					</div>
				</div>


                                
<div className="card" >
					<div className="card-header header-elements-inline">
						<h5 className="card-title">All Customers</h5>
						<div className="header-elements">
							<div className="list-icons">
		                		<a className="list-icons-item" data-action="collapse"></a>
		                		<a className="list-icons-item" data-action="reload"></a>
		                		<a className="list-icons-item" data-action="remove"></a>
		                	</div>
	                	</div>
					</div>

					<div className="card-body">
						

                       <div className="col-md-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
        <TableRecord data={content}  columns={tableHeader}  />}
            </div>
					</div>
				</div>




</Template>
);
       
}

export default React.memo(Customers)  
